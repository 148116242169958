@font-face {
  font-family: 'Noyh';
  src: url('fonts/Noyh-Bold.woff2') format('woff2'), /* Modern Browsers */
      url('fonts/Noyh-Bold.woff') format('woff'), /* Modern Browsers */
      url('fonts/Noyh-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'work_sansregular';
  src: url('fonts/worksans-regular-webfont.woff2') format('woff2'),
      url('fonts/worksans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'work_sanssemibld';
  src: url('fonts/worksans-semibold-webfont.woff2') format('woff2'),
  url('fonts/worksans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin noyh {
  font-family: 'Noyh';
  font-style: normal;
  font-weight: bold;
}

@mixin regular {
  font-family: 'work_sansregular';
  font-style: normal;
  font-weight: normal;
}

@mixin semibold {
  font-family: 'work_sanssemibld';
  font-style: normal;
  font-weight: normal;
}

@mixin h1 {
  @include noyh;
  font-size: rem-calc(32);
  line-height: rem-calc(30);
  color: $primary-color;
  text-align: center;

  @include breakpoint(large) {
    text-align: left;
    font-size: rem-calc(40);
    line-height: rem-calc(42);
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(70);
    line-height: rem-calc(66);
  }
}

@mixin h2 {
  @include noyh;
  font-size: rem-calc(30);
  line-height: rem-calc(30);
  color: $primary-color;

  @include breakpoint(large) {
    font-size: rem-calc(40);
    line-height: rem-calc(30);
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(58);
    line-height: rem-calc(44);
  }
}

@mixin h3 {
  @include noyh;
  font-size: rem-calc(30);
  line-height: rem-calc(28);
  color: $primary-color;
  margin-bottom: rem-calc(22);
  text-align: center;

  @include breakpoint(large) {
    text-align: left;
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(50);
    line-height: rem-calc(44);
    margin-bottom: rem-calc(36);
  }
}

@mixin p {
  @include regular;
  font-size: rem-calc(16);
  line-height: rem-calc(24);
  color: $primary-color;
  text-align: center;

  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: rem-calc(26);
    text-align: left;
  }

  em {
    color: #646668;
    font-style: normal;
  }
}

@mixin li {
  @include p;
  line-height: rem-calc(24);
  list-style-type: none;
  background-image: url(../images/icon-checkmark.svg);
  background-repeat: no-repeat;
  background-size: 12px auto;
  background-position: 0px 6px;
  padding-left: rem-calc(24);
  text-align: left;

  @include breakpoint(large) {
    background-size: 16px auto;
    background-position: 0px 8px;
    line-height: rem-calc(32);
    padding-left: rem-calc(32);
  }
}
