// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  outline: none;
  //position: absolute;
  //right: rem-calc(86);
  z-index: 1;
  top: 12px;
  padding-left: 0;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:focus {
    outline: none;
  }

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 1;
  display: inline-block;
  position: relative;
  top: 2px;

  @include breakpoint(xxlarge) {
    top: 0px;
  }
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::after,
  &::before {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    top: 8px !important;
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.is-active {
  .description {
    display: none;
  }
  
  .hamburger-box .hamburger-inner::after {
    top: 16px !important;
  }
}

.hamburger.is-active {
  @include breakpoint(xlarge) {
    margin-top: 7px;
  }
}
