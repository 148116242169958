footer {
  margin-top: rem-calc(60);
  padding-bottom: rem-calc(64);

  @include breakpoint(large) {
    padding-bottom: rem-calc(64);
  }

  @include breakpoint(xlarge) {
    padding-bottom: rem-calc(96);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(160);
  }

  a {
    @include semibold;
    color: $primary-color;
    text-decoration: none;
    display: block;

    @include breakpoint(xlarge) {
      display: inline-block;
    }

    &:hover {
      text-decoration: underline;
    }

    & + a {
      @include breakpoint(xlarge) {
        padding-left: rem-calc(16);
      }
    }
  }

  img {
    max-width: rem-calc(72);
    margin-bottom: rem-calc(56);

    @include breakpoint(xxlarge) {
      margin-bottom: 0;
    }
  }

  .columns {
    text-align: center;

    @include breakpoint(xlarge) {
      text-align: left;
    }
  }

  .columns:nth-child(2) {
    text-align: center;
  }

  .columns:last-child {
    margin-top: rem-calc(32);

    @include breakpoint(xlarge) {
      margin-top: 0;
      text-align: right;
    }

    a {
      @include regular;
    }

  }
}
