h1 {
    @include h1;
}

h2 {
    @include h2;
}

h3 {
    @include h3;
}

p {
    @include p;
}

.white {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        @include li;
    }
}

body {
    background-image: linear-gradient(0deg, #294162, #325078);
}

figure {
    margin: 0;

    figcaption {
        @include regular;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        color: $white;
        margin-top: rem-calc(8);
        margin-bottom: rem-calc(20);
        text-align: center;

        @include breakpoint(xxlarge) {
            margin-top: rem-calc(38);
            margin-bottom: rem-calc(56);
            font-size: rem-calc(17);
            line-height: rem-calc(27);
            margin-left: 8.33333%;
            text-align: left;
        }
    }
}

.page-type-projects {
    figure {
        position: relative;
        margin-bottom: rem-calc(56);

        figcaption {
            position: absolute;
            //top: rem-calc(-20);
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
            text-align: center;
            margin-left: 0;
            top: calc(100% + 8px);
            /*bottom: rem-calc(-24);*/

            @include breakpoint(large) {
                text-align: left;
                /*bottom: rem-calc(-32);*/
                margin-left: 8.33333%;
                //top: rem-calc(-26);
                width: auto;
            }

        }
    }
}

.single-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .close {
        margin: 0 auto rem-calc(26) rem-calc(16);
        width: rem-calc(20);
        height: rem-calc(20);
        display: inline-block;
        background-image: url(../images/icon-close.svg);
        position: absolute;

        /*    @media screen and (max-width: 47.5em) and (orientation: landscape) {
              width: rem-calc(20);
              height: rem-calc(20);
              margin: 0;
              position: absolute;
              top: rem-calc(-21);
              left: rem-calc(-31);
            }*/

        @include breakpoint(large) {
            width: rem-calc(40);
            height: rem-calc(40);
            left: 0;
        }
    }

    figure {
        position: relative;

        @media screen and (orientation: landscape) {
            height: 85vh;
            max-height: 85vh;
        }

        //width: 95vw;

        /*    @media screen and (max-width: 47.5em) and (orientation: landscape) {
              max-height: 80vh;
              width: 80%;
              margin: 0 auto rem-calc(48) auto;
              position: relative;
            }*/

        /*    //@include breakpoint(xxlarge) {
              height: 85vh;
              max-height: 85vh;
        /*      width: 90vw;
              max-width: 85vw;
              text-align: center;
            //}*/

        img {
            margin: rem-calc(48) auto 0 auto;

            @media screen and (orientation: landscape) {
                max-height: 90%;
            }

            @include breakpoint(large) {
                margin: rem-calc(80) auto 0 auto;
            }

        }

        figcaption {
            top: calc(100% + 20px);
        }

    }
}

.page-type-home {
    background-image: none;
}

.white {
    background-color: $white;
    padding-top: rem-calc(30);

    @include breakpoint(xxlarge) {
        padding-top: rem-calc(62);
    }

}

.play {
    width: 1.6rem;
    height: auto;
    display: block;
    margin: 0.6rem auto;

    @include breakpoint(large) {
        width: 3.2rem;
        margin: 1rem auto;
    }

    @include breakpoint(xxlarge) {
        width: 4rem;
        margin: 1.5rem auto;
    }

}

.blocks {
    margin: 0 auto;

    @include breakpoint(xxlarge) {
        margin: 0 auto;
    }

    .masonry, .masonry-gallery {
        margin: 0;
        padding: 0;

        @include breakpoint(large) {
            display: flex;
            flex-flow: row wrap;
        }
    }

    .masonry-column {
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint(large) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .item {
            list-style-type: none;
            position: relative;
            height: 60vw;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(large) {
                height: 27vw;
            }

        }

        &:last-child .item {
            @include breakpoint(large) {
                height: 54vw;
            }
        }
    }

    .masonry-gallery {
        .item {
            list-style-type: none;
            position: relative;
            height: 60vw;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 100%;
            max-width: 100%;

            @include breakpoint(large) {
                height: 27vw;
                flex: 0 0 50%;
                max-width: 50%;
            }

        }
    }

    .item {
        a:hover {
            .cloudy {
                opacity: 0;
                transition: all 0.3s ease;
            }

            .content {
                opacity: 0;
                transition: all 0.1s ease;
            }

            .image {
                opacity: 1;
            }

        }

        .cloudy {
            background-color: #325078;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            transition: all 0.2s ease;

            &.grey {
                background-color: #646668;

                .image {
                    opacity: 0.3;
                }
            }

            &.lightblue {
                background-color: #6e87a0;

                .image {
                    opacity: 0.3;
                }
            }
        }

        .image {
            transition: all 0.2s ease;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0.4;
        }

        .content {
            position: relative;
            z-index: 2;
            transition: all 0.1s ease;
            padding: 0 10%;

            @include breakpoint(large) {
                padding: 0 15%;
            }
        }

        h2, p {
            color: $white;
            text-align: center;
            margin: 0;
        }

        a {
            text-decoration: none;
        }

        p {
            @include regular;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            margin-top: rem-calc(8);

            @include breakpoint(large) {
                font-size: rem-calc(20);
                line-height: rem-calc(26);
                margin-top: rem-calc(16);
            }
        }
    }

}

.signature {
    img {
        max-width: rem-calc(90);

        @include breakpoint(large) {
            max-width: rem-calc(120);
        }

        @include breakpoint(xxlarge) {
            max-width: rem-calc(200);
        }
    }
}

.blocks + .row,
.bottom {
    ul {
        margin: 0 0 rem-calc(16) 0;
        padding: 0;

        @include breakpoint(large) {
            margin: rem-calc(64) 0 rem-calc(18) 0;
        }

        li {
            list-style-type: none;
            text-align: center;

            @include breakpoint(large) {
                text-align: left;
                display: inline-block;
                margin-right: rem-calc(16);
            }

            a {
                @include noyh;
                font-size: rem-calc(20);
                line-height: rem-calc(34);
                color: $primary-color;
                text-decoration: none;
                border-bottom: 1px solid $medium-gray;

                &:hover {
                    border-bottom: none;
                }

                @include breakpoint(large) {
                    font-size: rem-calc(26);
                    line-height: rem-calc(32);
                }
            }
        }
    }
}


.white p:last-child a,
.load-more {
    @include noyh;
    font-size: rem-calc(20);
    line-height: rem-calc(34);
    color: $primary-color;
    text-decoration: none;
    border-bottom: 1px solid $medium-gray;
    display: inline-block;
    cursor: pointer;

    @include breakpoint(large) {
        margin: rem-calc(46) 0 0 0;
        font-size: rem-calc(26);
        line-height: rem-calc(32);
    }

    &:hover {
        border-bottom: none;
    }
}

.button-more {
    display: block;
    text-align: center;

    .load-more:focus {
        outline: none;
    }
}

.gallery {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparentize(#193250, 0.05);
    z-index: -10;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease;
    //display: none;

    &.active {
        z-index: 10;
        visibility: visible;
        opacity: 1;
        transition: all 0.2s ease;
        //display: block;
    }

    .swiper-container .prev-next {
        /*margin-top: 1vh;*/
        position: relative;

        @include breakpoint(large) {
            margin-top: 4vh;
        }

        @include breakpoint(xxlarge) {
            margin-top: 6vh;
        }
    }

    .swiper-container .swiper-slide {
        opacity: 1;

        @include breakpoint(xxlarge) {
            max-width: 70vw;
        }

        @include breakpoint(xxxlarge) {
            max-width: 65vw;
        }

        @include breakpoint(xxxxlarge) {
            max-width: 50vw;
        }
    }
}


.page-type-services,
.page-type-company,
.page-type-team,
.page-type-contact {
    h1 {
        margin-top: 0;
        color: $success-color;

        @include breakpoint(xxlarge) {
            font-size: rem-calc(100);
            line-height: rem-calc(90);
        }

        @include breakpoint(xxxlarge) {
            font-size: rem-calc(130);
            line-height: rem-calc(110);
        }
    }

    p {
        color: $white;

        span {
            white-space: nowrap;
        }

        a {
            color: $white;
        }
    }
}

.page-type-company {
    .fslightbox-nav,
    .fslightbox-slide-btn-container {
        display: none
    }

    .swiper-slide figure {
        position: relative;

        .play {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    .white {
        figure {
            margin-top: rem-calc(16);
            text-align: center;

            @include breakpoint(xxlarge) {
                margin-top: rem-calc(48);
                text-align: left;
            }
        }

        figcaption {
            color: $primary-color;
            margin: rem-calc(3) 0 0 0;
            font-size: rem-calc(14);

            @include breakpoint(xxlarge) {
                margin: rem-calc(6) 0 0 0;
                font-size: rem-calc(17);
            }
        }
    }

    .more-left-padding {
        @include breakpoint(xxxxlarge) {
            padding-right: rem-calc(20);
        }
    }

    .more-right-padding {
        @include breakpoint(xxxxlarge) {
            padding-left: rem-calc(20);
        }
    }
}

.blue-top {
    padding-bottom: rem-calc(16);

    @include breakpoint(xxlarge) {
        padding-bottom: rem-calc(106);
    }
}

.page-type-team,
.page-type-contact {
    .blue-top {
        padding-bottom: rem-calc(16);

        @include breakpoint(xxlarge) {
            padding-bottom: rem-calc(62);
        }
    }

}

.page-type-team {

    .white {
        @include breakpoint(xxlarge) {
            padding-top: rem-calc(120);
        }
    }

    .column .member {
        @include breakpoint(xxlarge) {
            max-width: rem-calc(240);
        }
    }

    .member-entry {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: rem-calc(16);

        @include breakpoint(large) {
            display: block;
            margin-bottom: 0;
        }

        .member-image {
            flex: 0 0 25%;
            max-width: 25%;

            @include breakpoint(large) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            img {
                max-width: 100%;

                @include breakpoint(xxlarge) {
                    max-width: rem-calc(280);
                }
            }
        }

        h3 {
            text-align: left;
        }

        p {
            margin: 0;
            text-align: left;

            &.function {
                margin-bottom: rem-calc(8);
            }
        }

        p a {
            //@include p;
            @include semibold;
            font-size: rem-calc(17);
            line-height: rem-calc(24);
            border-bottom: none;
            margin: 0;
            text-align: left;
        }
    }


    .member-caption {
        // margin-top: rem-calc(8);
        margin-bottom: rem-calc(24);
        flex: 0 0 75%;
        max-width: 75%;
        padding-left: rem-calc(15);

        @include breakpoint(large) {
            padding-left: 0;
            margin-top: rem-calc(8);
        }

        @include breakpoint(xxlarge) {
            flex: 0 0 90%;
            max-width: 90%;
            margin-top: rem-calc(21);
            margin-bottom: rem-calc(72);
        }

        @include breakpoint(xxxxlarge) {
            flex: 0 0 80%;
            max-width: 80%;
        }
    }

    h3 {
        text-align: center;
        @include semibold;
        font-size: rem-calc(17);
        line-height: rem-calc(24);
        margin: 0;

        @include breakpoint(large) {
            text-align: left;
        }
    }

    p {
        @include regular;
        font-size: rem-calc(17);
        line-height: rem-calc(24);
        margin-top: 0.5rem;
    }
}

.white {
    p, p a {
        color: $primary-color;
    }
}

.gallery {
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }
    }
}

.swiper-container {
    //padding-top: rem-calc(160);

    .close {
        margin: rem-calc(20) auto rem-calc(26) rem-calc(32);
        width: rem-calc(20);
        height: rem-calc(20);
        display: inline-block;
        background-image: url(../images/icon-close.svg);

        @media screen and (max-width: 47.5em) and (orientation: landscape) {
            width: rem-calc(20);
            height: rem-calc(20);
            margin: rem-calc(10) auto rem-calc(10) rem-calc(32);
        }

        @include breakpoint(large) {
            position: absolute;
            top: rem-calc(9);
            width: rem-calc(40);
            height: rem-calc(40);
        }

    }


    .swiper-slide {
        padding-right: rem-calc(30);
        opacity: 0.4;
        transition: all 0.2s ease;

        &.swiper-slide-active {
            opacity: 1;
            transition: all 0.5s ease;
        }

        figure {
            @media screen and (max-width: 47.5em) and (orientation: landscape) {
                max-height: 75vh;
                width: 75%;
                margin: 0 auto rem-calc(48) auto;
            }

        }
    }

    .prev-next > .columns {
        text-align: center;
    }

    .prev-next-wrapper {
        display: none;

        @include breakpoint(large) {
            margin: rem-calc(32) auto rem-calc(26) auto;
            display: block;
        }

        @include breakpoint(xxlarge) {
            margin: rem-calc(60) auto rem-calc(26) auto;
        }
    }

    .swiper-button-prev, .swiper-button-next {
        display: inline-block;
        left: auto;
        right: auto;
        top: 0;
        margin-top: 0;
        position: relative;
        height: rem-calc(60);
        width: rem-calc(60);
        border: 3px solid #647d9b;
        border-radius: 100%;
        background-image: url(../images/arrow-right-white.svg);
        margin-left: rem-calc(15);
    }

    .swiper-button-prev {
        opacity: 0.5;
        transform: rotate(180deg);
        margin-right: rem-calc(15);
        margin-left: 0;
    }

    .swiper-wrapper .columns {
        padding-left: 0;
    }
}

.more-padding {
    @include breakpoint(xlarge) {
        padding-left: rem-calc(50);
        padding-right: rem-calc(50);
    }

    @include breakpoint(xxlarge) {
        padding-left: rem-calc(80);
        padding-right: rem-calc(80);
    }


    @include breakpoint(xxxxlarge) {
        padding-left: 0;
        padding-right: 0;
    }
}

.more-left-padding {
    @include breakpoint(xlarge) {
        padding-left: rem-calc(50);
    }

    @include breakpoint(xxlarge) {
        padding-left: rem-calc(80);
    }


    @include breakpoint(xxxxlarge) {
        padding-left: 0;
    }
}

.more-right-padding {
    @include breakpoint(xlarge) {
        padding-right: rem-calc(50);
    }

    @include breakpoint(xxlarge) {
        padding-right: rem-calc(80);
    }


    @include breakpoint(xxxxlarge) {
        padding-right: 0;
    }
}


#map {
    height: rem-calc(300);
    width: 100%;

    @include breakpoint(xxlarge) {
        height: rem-calc(450);
        z-index: 0;
    }
}

.fslightbox-container {
    background-color: transparentize(#193250, 0.05);

    .fslightbox-toolbar-button {
        margin: 1rem;
        width: rem-calc(20);
        height: rem-calc(20);
        //display: inline-block;
        background: none;
        background-image: url(../images/icon-close.svg);
        //position: absolute;

        @include breakpoint(large) {
          margin: 2rem;
            width: rem-calc(40);
            height: rem-calc(40);
        }

        svg {
            display: none;
        }
    }
}

.facebook {
    //width: rem-calc(20);
    margin: 0.5rem auto;
    height: rem-calc(20);
    width: rem-calc(30);
    overflow: hidden;
    text-indent: 100%;
    background-image: url(../images/icon--facebook.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include breakpoint(xlarge) {
        margin-top: 0;
        margin-bottom: -0.55rem !important;
        margin-left: auto;
        margin-right: 0;
        height: rem-calc(30);
        width: rem-calc(45);
    }
}