.page-type-home {

  header {
    background-image: linear-gradient( 0deg, #294162, #325078);
    height: rem-calc(280);

    @include breakpoint(large) {
      height: rem-calc(200);
    }

    @include breakpoint(xxlarge) {
      height: rem-calc(320);
    }

    .branding {
      text-align: center;
      position: absolute;
      top: 168px;
      width: 100%;

      @include breakpoint(large) {
        top: 100px;
      }

      @include breakpoint(xlarge) {
        text-align: right;
        padding-top: rem-calc(22);
        position: relative;
        top: 0;
      }

      @include breakpoint(xxlarge) {
        padding-top: rem-calc(56);
      }

      img {
        width: 100%;
        max-width: 270px;

        @include breakpoint(xxlarge) {
          max-width: rem-calc(405);
        }
      }
    }
  }

}


header {
  height: rem-calc(80);

  @include breakpoint(xxlarge) {
    height: rem-calc(220);
  }

  nav.brgr {

    @include breakpoint(xxlarge) {
      padding-top: rem-calc(50);
    }

    @include breakpoint(xxxlarge) {
      padding-top: rem-calc(42);
    }

    z-index: 10;
  }

  nav {
    padding-top: rem-calc(8);
    position: relative;

    @include breakpoint(xxlarge) {
      padding-top: rem-calc(53);
    }
    
    .menu {
      margin: 0;
      padding: 0;
      display: none;
      width: 100%;

      @include breakpoint(large) {
        padding: rem-calc(64) 0 rem-calc(64) rem-calc(32);
      }

      @include breakpoint(xxlarge) {
        padding: rem-calc(100) 0 rem-calc(100) rem-calc(50);
      }
    }

    ul {
      //position: absolute;
      //top: rem-calc(23);
      //left: rem-calc(24);
      //display: none;

      @include breakpoint(large) {
        column-count: 2;
      }

      @include breakpoint(xxlarge) {
        top: rem-calc(63);
        left: rem-calc(23);
      }

      li {
        list-style-type: none;
        padding-bottom: rem-calc(8);
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint(large) {
          flex: 0 0 42%;
          max-width: 42%;
          padding-bottom: rem-calc(32);
        }

        @include breakpoint(xxlarge) {
          flex: 0 0 35%;
          max-width: 35%;
          padding-bottom: rem-calc(50)
        }

        &.small {
          @include breakpoint(xxlarge) {
            padding-top: rem-calc(50)
          }

          a {
            @include regular;
            font-size: rem-calc(16);

            @include breakpoint(large) {
              font-size: rem-calc(18);
            }
          }
        }


        a {
          color: $white;
          text-decoration: none;
          @include semibold;
          font-size: rem-calc(24);
          text-align: center;
          display: block;
          padding: rem-calc(4) 0;

          @include breakpoint(large) {
            text-align: left;
          }

          @include breakpoint(xxlarge) {
            font-size: rem-calc(40);
            text-align: left;
          }

          &:hover {
            color: #647d9b;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          column-count: 1;

          li {
            padding-bottom: 0;
            max-width: 100%;
          }
        }

        li a {
          @include regular;
          padding: rem-calc(2) 0;
          font-size: rem-calc(16);

          @include breakpoint(large) {
            font-size: rem-calc(18);
          }

        }
      }
    }
  }

  .submenu-wrapper {
    display: none;

    @include breakpoint(xxlarge) {
      display: flex;
    }
  }

  .submenu {
    display: block;

    ul {
      display: none;
      top: 0;
      left: 0;
      position: relative;
      margin: 0;
      padding: 0;


      @include breakpoint(xxlarge) {
        display: flex;
        //position: absolute;
        top: rem-calc(15);
      }
    }
    
    li {
      display: inline-block;
      flex: none;
      vertical-align: top;
      width: auto !important;
      max-width: 70% !important;
    }

    a {
      color: $light-blue;
      padding-left: 0;
      padding-right: rem-calc(8);
      font-size: rem-calc(16);

      @include breakpoint(xxlarge) {
        padding-left: rem-calc(17);
      }
      //
      //@include breakpoint(xxxlarge) {
      //  font-size: rem-calc(20);
      //}
    }

    .is-active a {
      color: $white;

      @include breakpoint(xxlarge) {
/*        background-image: url(../images/icon-arrow-right-light-blue.svg);
        background-size: 32px 10px;
        background-repeat: no-repeat;
        background-position: 12px center;
        padding-left: rem-calc(54);*/
      }
    }

  }

  //.is-active + ul {
    //display: block;
    ////background-color: #325078;
    ////padding-right: rem-calc(8);
    //z-index: 10;
    //
    //@include breakpoint(xlarge) {
    //  background-color: transparent;
    //  margin-top: -6px;
    //}
  //}

  button .description {
    @include noyh;
    color: $white;
    font-size: rem-calc(18);
    padding-left: rem-calc(12);

    @include breakpoint(large) {
      padding-left: rem-calc(17);
    }

    @include breakpoint(xxlarge) {
      font-size: rem-calc(20);
    }

    @include breakpoint(xxxlarge) {
      font-size: rem-calc(28);
    }
  }

  .branding {
    text-align: center;
    position: absolute;
    top: 24px;
    right: 0;
    width: 65%;
    text-align: right;
    z-index: 10;

    @include breakpoint(xlarge) {
      text-align: right;
      padding-top: rem-calc(22);
      position: relative;
      top: 0px;
      width: auto;
      right: auto;
    }

    @include breakpoint(xxlarge) {
      padding-top: rem-calc(56);
    }


    img {
      width: 100%;
      max-width: 270px;

      //@include breakpoint(xlarge) {
      //  max-width: rem-calc(380);
      //}

      @include breakpoint(xxlarge) {
        max-width: rem-calc(405);
      }
    }
  }
}

.page-type-contact header .submenu li {
   max-width: 100% !important;
}

.menu-open {
  overflow: hidden;

  @include breakpoint(large) {
    overflow: auto;
  }

  .submenu-wrapper {
    display: none;
  }

  header {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient( 0deg, #294162, #325078);
    overflow: scroll;
    position: absolute;
    z-index: 50;

    @include breakpoint(large) {
      height: auto;
    }

    > .row {
      position: relative;

      .navi {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .row .branding {
    display: none;
    //z-index: 1;

    @include breakpoint(large) {
      display: block;
      position: absolute;
      top: rem-calc(24);
      right: 0px;
      width: 65%;
      text-align: right;
    }

    @include breakpoint(xlarge) {
      display: block;
      position: absolute;
      top: 0;
      //right: 1px;
    }

    img {
      @include breakpoint(xxlarge) {
        width: 405px;
      }
    }
  }

  .menu {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  main {
    padding-top: rem-calc(280);

    @include breakpoint(large) {
      padding-top: rem-calc(200);
    }

    @include breakpoint(xxlarge) {
      padding-top: rem-calc(320);
    }
  }
}
